<template>
  <page-title-component title="Version" />

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-3">
              <select class="form-control"
                      v-model="filter.type">
                <option value="">All Type</option>
                <option value="Update">Update</option>
                <option value="Feature">Feature</option>
              </select>
            </div>
            <div class="col-md-3">
              <select class="form-control"
                      v-model="filter.product_type">
                <option value="">All Product Type</option>
                <option value="Agency">Agency</option>
                <option value="User">User</option>
                <option value="Admin">Admin</option>
                <option value="API">API</option>
                <option value="Influencer">Influencer</option>
                <option value="iFrame">iFrame</option>
              </select>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Created At</th>
                <th>Show</th>
                <th>Type</th>
                <th>Task</th>
                <th>Deploy</th>
                <th>Version</th>
                <th>Product Type</th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in filteredVersions" :key="'version_'+i">
                <td>{{ item.created_at }}</td>
                <td>
                  <span class="badge badge-success" v-if="item.show">Yes</span>
                  <span class="badge badge-danger" v-else>No</span>
                </td>
                <td>{{ item.type }}</td>
                <td>{{ item.task }}</td>
                <td>
                  <span class="badge badge-success" v-if="item.deploy">Yes</span>
                  <span class="badge badge-danger" v-else>No</span>
                </td>
                <td>{{ item.version }}</td>
                <td>{{ item.product_type }}</td>
                <td class="text-right">
                  <button class="btn btn-sm btn-success"
                          v-if="!item.show"
                          :disabled="loadingStatus"
                          @click="changeStatus(item, true)">Show</button>
                  <button class="btn btn-sm btn-danger"
                          :disabled="loadingStatus"
                          v-else
                          @click="changeStatus(item, false)">Hide</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      versions: [],
      loadingStatus: false,
      filter: {
        type: '',
        product_type: ''
      }
    }
  },
  created() {
    this.loadVersion()
  },
  computed: {
    filteredVersions() {
      return this.versions.filter(ver => {
        return (!this.filter.type || ver.type.includes(this.filter.type)) &&
            (!this.filter.product_type || ver.product_type.includes(this.filter.product_type));
      });
    }
  },
  methods: {
    loadVersion() {
      this.axios.get('/version')
          .then((res) => {
            this.versions = res.data.data;
          })
    },
    changeStatus(item, status) {
      this.loadingStatus = true;

      this.axios.post('/version', {
        id: item.id,
        show: status
      }).then(() => {
        item.show = status;
      }).finally(() => {
        this.loadingStatus = false;
      })
    }
  }
}
</script>